import XLSX from 'xlsx'
import dayjs from 'dayjs'

export function exportOutbound (sheetData, fileName = dayjs().format('YYYY-MM')) {
  const arr = sheetData.reduce((list, value, index, rawList) => {
    // buyer: "123"
    // createTime: "2023-04-27 17:57:54"
    // money: "1"
    // number: 1
    // outboundTime: "2023-04-27"
    // outboundType: 1
    // price: "1"
    // quantity: 1
    // remark: "234"
    // specs: "123"
    // type: "123333"
    const row = {
      出库时间: value.outboundTime,
      类别: value.type,
      规格: value.specs,
      数量: value.quantity,
      单价: value.price,
      金额: value.money,
      件数: value.number,
      收货单位: value.buyer,
      备注: value.remark,
      创建时间: value.createTime
    }
    list.push(row)
    return list
  }, [])

  const sheet = XLSX.utils.json_to_sheet(arr)

  // 创建虚拟的workbook
  const wb = XLSX.utils.book_new()
  // 把sheet添加到workbook中
  XLSX.utils.book_append_sheet(wb, sheet, fileName)
  const workbookBlob = workbook2blob(wb)
  openDownload(workbookBlob, `${fileName}.xlsx`)

  function openDownload (blob, fileName = '123') {
    if (typeof blob === 'object' && blob instanceof Blob) {
      blob = URL.createObjectURL(blob) // 创建blob地址
    }
    const aLink = document.createElement('a')
    aLink.href = blob
    // HTML5新增的属性，指定保存文件名，可以不要后缀，注意，有时候 file:///模式下不会生效
    aLink.download = fileName || ''
    let event
    if (window.MouseEvent) event = new MouseEvent('click')
    //   移动端
    else {
      event = document.createEvent('MouseEvents')
      event.initMouseEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null)
    }
    aLink.dispatchEvent(event)
  }

  function workbook2blob (workbook) {
  // 生成excel的配置项
    const wopts = {
      // 要生成的文件类型
      bookType: 'xlsx',
      // // 是否生成Shared String Table，官方解释是，如果开启生成速度会下降，但在低版本IOS设备上有更好的兼容性
      bookSST: false,
      type: 'binary'
    }
    const wbout = XLSX.write(workbook, wopts)
    // 将字符串转ArrayBuffer
    function s2ab (s) {
      const buf = new ArrayBuffer(s.length)
      const view = new Uint8Array(buf)
      for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff
      return buf
    }
    const blob = new Blob([s2ab(wbout)], {
      type: 'application/octet-stream'
    })
    return blob
  }
}
