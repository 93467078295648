<template>
  <el-dialog
    v-model="visible"
    :title="title"
    width="640px"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    class="add"
  >
    <el-form ref="form" v-loading="loading" :model="ruleForm" :rules="rules" label-width="80px">
      <el-form-item label="类别" prop="type">
        <el-select
          v-model="ruleForm.type"
          filterable
          placeholder="请选择"
          @change="onChange"
        >
          <el-option
            v-for="(item, index) in types"
            :key="index"
            :label="item.productTypeName"
            :value="item._id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="规格" prop="type">
        <el-select
          :disabled="!ruleForm.type"
          filterable
          v-model="ruleForm.specs"
          placeholder="请选择"
        >
          <el-option
            v-for="(item, index) in specs"
            :key="index"
            :label="item.productSpecsName"
            :value="item._id"
          ></el-option>
        </el-select>
        <el-button :disabled="!ruleForm.specs" type="text" style="margin-left: 10px;" @click="useSpecPrice">使用规格单价(¥{{curSpecPrice}})</el-button>
      </el-form-item>

      <el-form-item label="出库类型" prop="outboundType">
        <el-radio-group v-model="ruleForm.outboundType">
          <el-radio :label="1">出库</el-radio>
          <el-radio :label="2">退仓</el-radio>
          <el-radio :label="3">报废</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="单价" prop="price" >
        <el-input v-model="ruleForm.price" type="number"></el-input>
      </el-form-item>
      <el-form-item label="数量" prop="quantity">
        <el-input v-model="ruleForm.quantity" type="number"></el-input>
      </el-form-item>
      <el-form-item label="价值金额" >
        {{money}}
      </el-form-item>
      <el-form-item label="件数" prop="number">
        <el-input v-model="ruleForm.number" type="number"></el-input>
      </el-form-item>
      <el-form-item label="收货单位" prop="buyer">
        <el-select
          v-model="ruleForm.buyer"
          filterable
          placeholder="请选择"
        >
          <el-option
            v-for="(item, index) in buyerList"
            :key="index"
            :label="item.productBuyerName"
            :value="item._id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="出库时间" prop="outboundTime">
        <el-date-picker
          v-model="ruleForm.outboundTime"
          type="date"
          value-format="YYYY-MM-DD"
          placeholder="请选择"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="备注">
        <el-input v-model="ruleForm.remark"></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button @click="visible = false">取消</el-button>
      <el-button type="primary" @click="onSubmit">保存</el-button>
    </template>
  </el-dialog>
</template>

<script>
import _ from 'lodash'
import { ElMessage } from 'element-plus'
import { ref, computed, reactive, toRefs, onMounted } from 'vue'
import Big from 'big.js'

// import * as math from 'mathjs'
import {
  getAllTypeApi,
  getSpecByTypeApi,
  saveOutboundApi,
  // getStockCountApi,
  getOutboundDetailApi,
  getBuyerListApi
} from './fetch'

export default {
  props: {
    refreshList: Function,
    id: String
  },
  setup (props) {
    const form = ref(null)
    const state = reactive({
      visible: true,
      loading: false,
      ruleForm: {
        type: '',
        specs: '',
        quantity: undefined,
        price: undefined,
        number: undefined,
        outboundType: 1,
        outboundTime: '',
        buyer: '',
        remark: ''
      },
      manualInput: false,
      types: [],
      specs: [],
      companyList: [],
      buyerList: [],
      currentStock: null,
      rules: {
        buyer: [
          { required: true, message: '请输入', trigger: ['blur', 'change'] }
        ],
        type: [
          { required: true, message: '请输入', trigger: ['blur', 'change'] }
        ],
        specs: [
          { required: true, message: '请输入', trigger: ['blur', 'change'] }
        ],
        quantity: [
          { required: true, message: '请输入', trigger: ['blur', 'change'] }
        ],
        price: [
          { required: true, message: '请输入', trigger: ['blur', 'change'] }
        ],
        number: [
          { required: true, message: '请输入', trigger: ['blur', 'change'] }
        ],
        outboundTime: [
          { required: true, message: '请选择', trigger: ['blur', 'change'] }
        ],
        outboundType: [
          { required: true, message: '请选择', trigger: ['blur', 'change'] }
        ]
      }
    })

    onMounted(async () => {
      state.loading = true
      state.types = await getAllTypeApi()
      state.buyerList = await getBuyerListApi()
      if (props.id) {
        state.ruleForm = await getOutboundDetailApi({
          id: props.id
        })
        state.specs = await getSpecByTypeApi({ productTypeId: state.ruleForm.type })
      }
      state.loading = false
    })

    const title = computed(() => {
      return props.id ? '编辑出库' : '新增出库'
    })

    const money = computed(() => {
      if (state.ruleForm.price && state.ruleForm.quantity) {
        return new Big(state.ruleForm.quantity).times(state.ruleForm.price).toFixed(2)
      } else {
        return 0
      }
    })

    const curSpecPrice = computed(() => {
      return state.specs.find(bar => bar._id === state.ruleForm.specs)?.productSpecPrice || ''
    })

    const onSubmit = async () => {
      try {
        await form.value.validate()
        const data = _.cloneDeep(state.ruleForm)
        data.quantity = +data.quantity
        data.number = +data.number
        data.price = +data.price
        data.money = new Big(data.quantity).times(data.price)
        await saveOutboundApi({
          ...data
        })
        state.visible = false
        props.refreshList()
        ElMessage({
          message: '保存成功！',
          type: 'success'
        })
      } catch (e) {
        ElMessage({
          message: e,
          type: 'error'
        })
      }
    }

    const onChange = async (id) => {
      state.ruleForm.specs = undefined
      state.currentStock = null
      state.specs = await getSpecByTypeApi({ productTypeId: id })
    }

    const switchManualInput = () => {
      state.manualInput = !state.manualInput
    }

    const useSpecPrice = () => {
      state.ruleForm.price = state.specs.find(bar => bar._id === state.ruleForm.specs)?.productSpecPrice
    }

    return {
      ...toRefs(state),
      form,
      title,
      onSubmit,
      onChange,
      money,
      curSpecPrice,
      // handleSpecChange,
      switchManualInput,
      useSpecPrice
    }
  }
}
</script>

<style lang="stylus" scoped>
.currentStock {
  border: 1px solid #ccc;
  padding: 10px;
  margin-bottom: 15px;

  .current, .diff {
    display: flex;
    flex-wrap: wrap;
    border-bottom: 1px solid #ccc;
    margin-bottom: 5px;
    padding-bottom: 5px;

    .currentDetail_item {
      width: 190px;
      white-space: nowrap;

      .label {
        display: inline-block;
        width: 112px;
        text-align: right;
      }
    }
  }
}
</style>
